import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import "./Patient.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function Patients(props) {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    async function getAllPatients() {
      try {
        const response = await axios.get(`${BASE_URL}/patient/allpatients`);
        const patients = response.data;
        console.log(patients);
        setPatients(patients);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    }

    getAllPatients();
  }, []);

  const gotopatientdetail = (patientId) => {
    if (props.admin) {
      navigate(`/admin/patient/patient-detail/${patientId}`);
    } else {
      navigate(`/therapist/patient/patient-detail/${patientId}`, {
        state: { isEdit: false },
      });
    }
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="xl"
        sx={{
          mt: 4,
          mb: 4,
        }}
      >
        <div>
          <h5 style={{ fontWeight: "600" }}>All Patients</h5>
        </div>
        {/* <button className='addAppointmentBtn'>Add <span>an Appointment</span> <AddIcon /></button> */}
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px 0 10px 10px",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          {patients?.map((patient) => (
            <Grid
              key={patient._id}
              container
              spacing={3}
              item
              xs={12}
              md={5}
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={5} sm={4} md={6}>
                {!patient.profilePic ? (
                  <img
                    className="patientImg"
                    src={require("../../images/person.jpg")}
                    alt="ptientImg"
                  />
                ) : (
                  <img
                    className="patientImg"
                    src={`${BASE_URL}/upload/${patient.profilePic}`}
                    alt="ptientImg"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <h5>{patient.studentName}</h5>
                <h6>{`${patient.country} (${patient.timeZone})`}</h6>
                <Button
                  onClick={() => gotopatientdetail(patient._id)}
                  sx={{ backgroundColor: "#1e45ac" }}
                  variant="contained"
                >
                  View Profile
                </Button>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
