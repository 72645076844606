import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { BASE_URL } from "../apiConfig";

const currentDate = new Date();

const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0");
const day = String(currentDate.getDate()).padStart(2, "0");
const formattedDate = `${year}-${month}-${day}`;
const initialValue = dayjs(formattedDate);

function ServerDay(props) {
  const {
    day,
    outsideCurrentMonth,
    highlightedDates,
    handleDateClick,
    ...other
  } = props;
  const dayNumber = day.date();

  const isSelected =
    !outsideCurrentMonth && highlightedDates.includes(dayNumber);

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "🟢" : undefined}
      onClick={() => handleDateClick(dayNumber)}
      style={{ cursor: "pointer" }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest({
  data,
  therapistId,
  onSlotDataReceived,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDates, setLoadingDates] = useState(true);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    const currentMonth = dayjs().format("MMM");
    setSelectedMonth(currentMonth);

    const fetchAvailableDates = () => {
      fetch(`${BASE_URL}/slot/filterbymonth/${therapistId}/${currentMonth}`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setHighlightedDates(data);
          setLoadingDates(false);
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
        });
    };

    fetchAvailableDates();

    setIsLoading(false);
  }, [data, therapistId]);

  const handleMonthChange = (date) => {
    setIsLoading(true);
    console.log(date);
    onSlotDataReceived([]);
    setHighlightedDates([]);
    const month = dayjs(date).format("MMM");
    console.log("selected month", month);
    setSelectedMonth(month);

    fetch(`${BASE_URL}/slot/filterbymonth/${therapistId}/${month}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setHighlightedDates(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching slot data:", error);
        setIsLoading(false);
      });
  };

  const handleDateClick = (selectedDay) => {
    setSelectedDate(selectedDay);

    const combinedDate = `${selectedMonth} ${selectedDay}`;

    const fetchSlotData = () => {
      fetch(`${BASE_URL}/slot/filter/${therapistId}/${combinedDate}`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          onSlotDataReceived(data);
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
        });
    };

    fetchSlotData();
  };

  return (
    <>
      {loadingDates ? (
        <h6>Loading available slots...</h6>
      ) : (
        <h6>Click on a highlighted ("🟢") date to view available slots.</h6>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          defaultValue={dayjs()}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          onDateChange={handleDateClick}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDates,
              handleDateClick,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}
