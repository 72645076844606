import React from "react";
import "./Why.css";

function Why() {
  return (
    <div className="why">
      <img
        className="sun"
        src={require("../../assets/sun-removebg-preview.png")}
        alt="sun"
      />
      <div className="container whyDiv">
        <h3>WHY US?</h3>

        <div className="whyContentDiv">
          <img src={require("../../assets/why.webp")} alt="whyimg" />

          <ul>
            <li>
              Our custom & unique methodology blends student training with
              parental training on practical approaches that can be incorporated
              in day-to-day life to maximize the opportunities for speech and
              communication thereby ensuring the right stimuli at the right age.
              The more you practice the better you become!
            </li>
            <li>
              Every week, we provide you just enough information and material
              required for you to perform the therapy activities for the week.
              No information overload and hence no overwhelming!
            </li>
            <li>
              We help set short term and realistic goals and devise strategies
              to fulfil them with the relevant & science-based methodologies
              based on the essence of not just the traditional verbal and
              non-verbal communication & speech therapy but also advanced
              techniques such as articulation therapy, prompt therapy, music
              therapy, augmented communication using AAC device and many more.
              Quick wins keep you motivated!
            </li>
            <li>
              We progressively enable caretakers/parents to become
              self-sufficient rather than being dependent on therapists forever.
              You are Your Child's Best Advocate!
            </li>
            <li>
              We offer you a portal which is both mobile and web friendly where
              you can book sessions with the desired therapist at your
              convenient time, review previous session recordings, track the
              results of daily therapy activities in a easy to use tabular
              format, review instruction/video against each of the activities
              that helps you perform the activity, be able to print the
              supporting document required for the activity, upload the video of
              you performing the activities and seek the feedback for the same
              from the therapist and easily visualize the therapy progress over
              a period of time in a chart and many more. Something can be
              improved if and only if it can be measured!
            </li>
            <li>
              We provide you with printables customized for your child’s need so
              that you can spend your valuable time only on therapy/activities
              rather than preparing materials, deciding activities etc. You are
              good to start with your activities right after the online session
              with the therapist from day 1 each week.The speed at which you act
              is directly proportional to the speed at which you reap the
              benefits!
            </li>
            <li>
              We offer you 4 online sessions per month and each session lasts
              for the duration of 45 mins and consists of first 30 mins 1X1
              student therapy and next 15 mins of parental guidance/training.
              This is perhaps the most affordable and reasonable pricing for a
              quality speech therapy!
            </li>
            <li>
              There is a grace period for first one month wherein if you don’t
              like our therapy, you will be refunded the entire amount with no
              questions asked!
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Why;
