import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../apiConfig";
import axios from "axios";

export default function GoogleCalendar() {
  const [embedId, setEmbedId] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [codeClient, setCodeClient] = useState({}); // auth code flow
  const [isAuth, setIsAuth] = useState(false);

  const CLIENT_ID =
    "125139459857-2qoo7u4q15u29hip89j7b2dicb1r7rtv.apps.googleusercontent.com";
  function handleGoogleAuth() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = async () => {
      const codeClient = window.google.accounts.oauth2.initCodeClient({
      // const codeClient = await google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/calendar",
        ux_mode: "popup",
        callback: (response) => {
          const code = response.code;
          const code_receiver_uri = `${BASE_URL}/therapist/googleAuthFlow`;
          fetch(code_receiver_uri, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": "XmlHttpRequest",
            },
            body: `code=${code}`,
            credentials: "include",
          })
            .then((response) => response.text())
            .then((data) => {
              console.log(data);
            })
            .catch((error) => {
              console.error("Error in fetch:", error);
            });
        },
      });

      codeClient.requestCode();
    };

    document.body.appendChild(script);
  }

  useEffect(() => {
    const fetchCalendarDetail = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/therapist/calendarData`, {
          withCredentials: true,
        });
        setEmbedId(response.data.id);
        setTimeZone(response.data.timeZone);
      } catch (error) {
        const { errorName, errorDesc, errorCode } = error?.response?.data;
        if (
          errorName === "invalid_grant" ||
          errorCode === 400 ||
          error?.response?.status === 400
        ) {
          handleGoogleAuth();
        }
      }
    };

    fetchCalendarDetail();
  }, []);

  return (
    <>
      <button className="btn btn-primary" onClick={handleGoogleAuth}>
        View calendar with events
      </button>

      <iframe
        title="My Calendar"
        src={`https://calendar.google.com/calendar/embed?src=${encodeURIComponent(
          embedId
        )}&ctz=${encodeURIComponent(
          timeZone
        )}&showTitle=0&showPrint=0&showCalendars=0`}
        style={{
          borderWidth: "0",
          // border: "0",
          width: "100%",
          height: "100vh",
          frameborder: "0",
          scrolling: "no",
        }}
      ></iframe>
    </>
  );
}
