import React from "react";
import "./Hero.css";

function Hero() {
  const learnMore = () => {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="hero" id="home">
      <div className="marquee-wrapper">
        <div class="marquee">
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
          <p>Speech therapy for anyone, anytime and anywhere...&nbsp;</p>
        </div>
      </div>

      <h1>
        Speech and Language Therapy - <span>reimagined</span>
      </h1>
      <p>engaging, cost-effective, flexible, data-driven.</p>
      <button onClick={learnMore} className="learn-more-btn">
        Learn more
      </button>
    </div>
  );
}

export default Hero;
