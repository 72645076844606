import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "../../apiConfig";
import { useNavigate } from "react-router-dom";

import "./Signin.css";

const CLIENT_ID =
  "125139459857-2qoo7u4q15u29hip89j7b2dicb1r7rtv.apps.googleusercontent.com";

function Signin(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    /* global google */
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = () => {
      google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleCallbackResponse,
      });

      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "large",
      });
    };
    document.body.appendChild(script);

    return () => {
      // Clean up the script if component unmounts
      document.body.removeChild(script);
    };
  }, []);

  function handleCallbackResponse(response) {
    //the response in function parameter is coming from google identity services
    console.log("Encoded JWT ID token: " + response.credential);
    setIdToken(response.credential);
    let userObject = jwtDecode(response.credential);
    console.log(userObject);
    // setUser(userObject);
    const userEmail = userObject.email;

    let apiEndpoint = "";

    if (props.page === "therapist") {
      apiEndpoint = "therapistLogin";
      console.log(apiEndpoint);
    } else {
      apiEndpoint = "patientLogin";
      console.log(apiEndpoint);
    }

    // document.getElementById("signInDiv").hidden = true;
    //TO SEND ID TOKEN TO BACKEND AFTER GOOGLE AUTHENTICATION to check if the user with this email is registered or not and accordingly redirect them registration page or dashboard
    fetch(`${BASE_URL}/${apiEndpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        // Set custom header for CSRF
        "X-Requested-With": "XmlHttpRequest",
      },
      body: JSON.stringify({ decodedIdToken: userObject }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Token ID response: " + JSON.stringify(data));
        if (data.status === true) {
          //already registered
          if (props.page === "therapist") {
            navigate("/therapist");
          } else {
            navigate("/patient");
          }
        } else {
          //new registration
          if (props.page === "therapist") {
            navigate("/therapist/signup", { state: { email: userEmail } });
          } else {
            navigate("/patient/signup", { state: { email: userEmail } });
          }
        }
      })
      .catch((error) => {
        console.error("Error in fetch:", error);
      });
  }

  return (
    <div className="signin p-3">
      <div className="row  div-container-row justify-content-center">
        <div className="col-8 col-sm-6 col-lg-4 p-3 div-container d-flex flex-column justify-content-center align-items-center">
          {props.page === "therapist" ? (
            <h4>Continue as a Therapist</h4>
          ) : (
            <h4>Continue as a Student</h4>
          )}
          <div id="signInDiv"></div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
