import React from "react";
import "./Program.css";
import Lottie from "lottie-react";
import animationData from "../../assets/animation_lmamnh83.json";

function Program() {
  return (
    <div className="program-section" id="program">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fdf5f2"
          fill-opacity="1"
          d="M0,96L60,90.7C120,85,240,75,360,90.7C480,107,600,149,720,154.7C840,160,960,128,1080,117.3C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
      <div className="program container">
        <h3>PROGRAM STRUCTURE</h3>
        <div className="row mt-4 py-2">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/one.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              For the first time, Parent/Caretaker registers with us by paying
              the monthly subscription fee for that month. The registered user
              can review all the therapists, their credentials and availability
              for the upcoming weeks on our portal and choose to book a therapy
              session with the desired therapist for the immediate next week or
              for all the 4 weeks. Our portal offers an interactive calendar
              integrated with Google calendar to make session booking seamless.
              Parent can choose to book the same therapist every week or a
              different therapist if they wish to do so. Upon confirmation from
              the therapist, a Google meeting invite will be sent to both the
              registered user and the therapist.
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/two.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              An online session of 45 mins is conducted from the chosen
              therapist for that week. The session consists of 30 mins of 1X1
              session with the therapy seeker/student and 15 mins of
              enablement/advisory session with the parent/caretaker. Parents can
              avail up to 4 sessions per month, i.e., 45 mins session each week
              of the month.
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/3.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              Post each therapy session,
              <ul>
                <li>
                  Recorded therapy session is uploaded to portal for future
                  reference from the parents/caretaker
                </li>
                <li>
                  Activities for the week will be populated in the activity
                  tracker along with the textual or video instructions and
                  printables/supporting documents for each of the activities.
                </li>
              </ul>
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2  col-xl-1 program-left">
            <img src={require("../../assets/4.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              Parent/Caretaker shall review the instructions and perform
              activities during the week and track the activity result/status in
              the tracker via mobile/web portal in their profile.
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/5.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              Parent/Caretaker shall upload the video of them performing
              therapy/activity with the student sometime during the mid-week.
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/6.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              The therapist who took the session shall review the video and
              provide his/her feedback in the portal right next to the video so
              that parents/caretakers can review the same and improvise their
              interaction with the student.{" "}
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/7.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>The same cycle repeats the next week.</h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/8.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              Parents/caretakers shall have access to a Facebook page and
              WhatsApp group to post their queries and get a quick response from
              one of our speech therapists.
            </h5>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/9.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              Using our portal, parents/caretakers can,
              <ul style={{ listStyleType: "-" }}>
                <li>
                  Access all the therapy session recordings, activities for the
                  week, materials/printables and demo videos specific to the
                  activities for the week in order to achieve the short-term
                  goals
                </li>
                <li>
                  Review the progress over time in a Dashboard in the parent's
                  profile.
                </li>
                <li>
                  Review therapists' availability among a pool of highly
                  qualified therapists and be able to book a session with any
                  therapist each week without depending on a single therapist.
                </li>
                <li>
                  Ability to perform activities and track status using an
                  easy-to-use portal.
                </li>
              </ul>
            </h5>
          </div>
        </div>

        <div className="row py-3">
          <div className="col-2 col-sm-2 col-xl-1 program-left">
            <img src={require("../../assets/confetti.png")} alt="one" />
          </div>
          <div className="col-sm-10 program-right">
            <h5>
              You are getting all this for just 80 USD per month! Wish to give
              it a try? Avail one month membership with 100% refund guaranteed
              and experience our services. Hurry up & register now and provide
              the best support that you can to enable your child to communicate!
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Program;
