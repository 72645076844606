import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../apiConfig";
import axios from "axios";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

export default function PatientCalendar() {
  const [embedId, setEmbedId] = useState("");
  const [timeZone, setTimeZone] = useState("");

  const CLIENT_ID =
    "125139459857-2qoo7u4q15u29hip89j7b2dicb1r7rtv.apps.googleusercontent.com";

  function handleGoogleAuth() {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;

    script.onload = async () => {
      // const codeClient = await google.accounts.oauth2.initCodeClient({
        const codeClient = window.google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/calendar.readonly",
        ux_mode: "popup",
        callback: (response) => {
          const code = response.code;
          const code_receiver_uri = `${BASE_URL}/patient/googleAuthFlow`;

          fetch(code_receiver_uri, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": "XmlHttpRequest",
            },
            body: `code=${code}`,
            credentials: "include",
          })
            .then((response) => response.text())
            .then((data) => {
              console.log(data);
            })
            .catch((error) => {
              console.error(error);
            });
        },
      });
      codeClient.requestCode();
    };

    document.body.appendChild(script);
  }

  useEffect(() => {
    const fetchCalendarDetail = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/patient/calendarData`, {
          withCredentials: true,
        });
        setEmbedId(response.data.id);
        setTimeZone(response.data.timeZone);
      } catch (error) {
        const { errorName, errorDesc, errorCode } = error?.response?.data;
        if (
          errorName === "invalid_grant" ||
          errorCode === 400 ||
          error?.response?.status === 400 //when refreshToken has null value
        ) {
          handleGoogleAuth();
        }
      }
    };

    fetchCalendarDetail();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />

      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px 0 10px 0",
        }}
      >
        <button className="btn btn-primary" onClick={handleGoogleAuth}>
          View calendar with events
        </button>

        <iframe
          title="My Calendar"
          src={`https://calendar.google.com/calendar/embed?src=${encodeURIComponent(
            embedId
          )}&ctz=${encodeURIComponent(
            timeZone
          )}&showTitle=0&showPrint=0&showCalendars=0`}
          style={{
            borderWidth: "0",
            // border: "0",
            width: "100%",
            height: "100vh",
            frameborder: "0",
            scrolling: "no",
          }}
        ></iframe>
      </Container>
    </Box>
  );
}
