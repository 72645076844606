import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import "./Appointment.css";
import ActivityPage from "../../components/ActivityPage";
import { Grid } from "@mui/material";
import Chart from "../../components/patient/Chart";
import { toast, ToastContainer } from "react-toastify";

export default function Progress() {
  const [progressData, setProgressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actualGraph, setActualGraph] = useState([]);
  const [expectationGraph, setExpectationGraph] = useState([]);

  useEffect(() => {
    const createEmptyTableData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/patient/details`, {
          withCredentials: true,
        });
        const subscriptions = response.data.subscriptionNum;

        const numYears = Math.floor(subscriptions / 12) + 1;
        const numMonthsInLastYear = subscriptions % 12;

        const createEmptyWeekData = (weekNumber) => {
          return {
            weekNumber: weekNumber,
            therapist: null,
            therapistName: "Therapist's name",
            meetLink: "google meet link",
            sessionRecording: "Session recording link",
            tips: {
              one: "Tip 1 here",
              two: "Tip 2 here",
              three: "Tip 3 here",
            },
            patientVideo: "Patient's video link",
            videoReview: "Therapist's review here",
            activities: Array.from({ length: 5 }, (_, activityIndex) => ({
              activityNumber: activityIndex + 1,
              instructions: "Instructions link",
              days: Array.from({ length: 7 }, (_, dayIndex) => ({
                day: dayIndex + 1,
                trials: Array(2).fill("none"),
              })),
            })),
          };
        };

        const createEmptyMonthData = (monthNumber) => {
          return {
            monthNumber: monthNumber,
            weeks: [
              createEmptyWeekData(1),
              createEmptyWeekData(2),
              createEmptyWeekData(3),
              createEmptyWeekData(4),
            ],
          };
        };

        const createEmptyYearData = (year, numMonths) => {
          return {
            year: year,
            months: Array.from({ length: numMonths }, (_, monthIndex) =>
              createEmptyMonthData(monthIndex + 1)
            ),
          };
        };

        const emptyProgressData = Array.from(
          { length: numYears },
          (_, yearIndex) => createEmptyYearData(yearIndex + 1, 12)
        );

        if (numMonthsInLastYear > 0) {
          const lastYearIndex = emptyProgressData.length - 1;
          emptyProgressData[lastYearIndex].months.splice(numMonthsInLastYear);

          if (emptyProgressData[lastYearIndex].months.length === 0) {
            emptyProgressData.pop();
          }
        }

        const fetchProgressData = async () => {
          try {
            const response = await axios.get(
              `${BASE_URL}/patient/get/progress`,
              {
                withCredentials: true,
              }
            );
            const dataFetched = response.data.progressData;

            if (dataFetched.length === 0) {
              setProgressData(emptyProgressData);
              saveProgressDataToBackend("new", emptyProgressData);
            } else {
              setProgressData(response.data.progressData);
            }

            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        };

        await fetchProgressData();
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    createEmptyTableData();
  }, []);

  const handleCellChange = (
    yearIndex,
    monthIndex,
    weekIndex,
    dayIndex,
    activityIndex,
    trial,
    newValue
  ) => {
    setProgressData((prevProgressData) => {
      const updatedProgressData = [...prevProgressData];
      updatedProgressData[yearIndex].months[monthIndex].weeks[
        weekIndex
      ].activities[activityIndex].days[dayIndex].trials[trial] = newValue;

      saveProgressDataToBackend("update", updatedProgressData);

      return updatedProgressData;
    });
  };

  const saveProgressDataToBackend = async (action, updatedProgressData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/patient/update/progress`,
        {
          progressData: updatedProgressData,
        },
        { withCredentials: true }
      );

      if (action === "update") {
        toast.success("Progress saved!");
        console.log("Progress data saved to backend!");
      }
    } catch (error) {
      console.error("Error saving progress data:", error);
    }
  };

  useEffect(() => {
    const trialsData = [];
    let trialsValueArray = [];
    const progressGraphArray = [];
    const expectationGraphArray = [];
    progressData.forEach((yearData) => {
      const { year, months } = yearData;
      months.forEach((monthData) => {
        const { monthNumber, weeks } = monthData;
        weeks.forEach((weekData) => {
          const { weekNumber, activities } = weekData;
          for (let day = 1; day <= 7; day++) {
            activities.forEach((activityData) => {
              const { activityNumber, days } = activityData;
              const dayData = days.find((dayData) => dayData.day === day);
              if (dayData) {
                const { trials } = dayData;
                const trialValue1 = trials[0];
                trialsData.push({
                  activity: activityNumber,
                  day: day,
                  trial: "1",
                  trialValue: trialValue1,
                });
              }
            });
            activities.forEach((activityData) => {
              const { activityNumber, days } = activityData;
              const dayData = days.find((dayData) => dayData.day === day);
              if (dayData) {
                const { trials } = dayData;
                const trialValue2 = trials[1];

                trialsData.push({
                  activity: activityNumber,
                  day: day,
                  trial: "2",
                  trialValue: trialValue2,
                });
              }
            });
          }
        });
      });
    });
    trialsValueArray = trialsData?.map((data) => data.trialValue);
    let progress = 0;
    let expectation = 0;
    let stopAddingValues = false;
    trialsValueArray.forEach((value) => {
      if (!stopAddingValues) {
        expectation++;
        expectationGraphArray.push(expectation);
        if (value === "success") {
          progress++;
          progressGraphArray.push(progress);
        } else {
          progressGraphArray.push(progress);
        }

        if (value === "none") {
          stopAddingValues = true;
        }
      }
    });
    setActualGraph(progressGraphArray);
    setExpectationGraph(expectationGraphArray);
  }, [progressData]);

  async function patientVideoUploadHandler(
    yearIndex,
    monthIndex,
    weekIndex,
    yearDataArray
  ) {
    try {
      const response = await axios.post(
        `${BASE_URL}/patient/update/patientvideo`,
        {
          progressData: yearDataArray,
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success("Video saved to profile!");
      } else {
        toast.error("An error occurred!");
      }
    } catch (error) {
      toast.error("An error occurred!");
      console.error("Error saving video:", error);
    }
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  const labels = expectationGraph;

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Expected Progress",
        data: expectationGraph,
        borderColor: "#00bf63",
      },
      {
        label: "Actual Progress",
        data: actualGraph,
        fill: false,
        borderColor: "#2242a3",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Days",
        },
      },
      y: {
        title: {
          display: true,
          text: "Trials",
        },
      },
    },
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <ToastContainer />
      <Toolbar />

      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
        }}
      >
        <h4>My Progress</h4>
        <Grid
          container
          mt={4}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            padding: "10px 20px 30px 20px",
          }}
        >
          <ActivityPage
            who="therapist"
            patientData={progressData}
            onCellChange={handleCellChange}
            onTableUpdate={patientVideoUploadHandler}
          />
        </Grid>

        <Grid item xs={12} lg="6">
          <Chart data={data} options={options} />
        </Grid>
      </Container>
    </Box>
  );
}
